html,
body,
#root {
  height: 100%;
  font-family: 'test', 'PingFang SC', Arial !important;
  overflow: hidden;
  background-color: transparent;
}

body {
  color: $text-0;
  font-size: 14px;
}

// 默认弹窗遮罩没有颜色
.am-toast.am-toast-mask {
  background: rgba(0, 0, 0, .4);
}

.ant-modal-wrap {
  position: absolute !important;
}

.ant-alert-banner {
  width: 100%;
}

video::-internal-media-controls-download-button {
  display: none;
}
